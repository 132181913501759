.mv-options {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}

.mv-options-item {
    margin: 0 10px;
    display: flex;
    align-items: center;
}
