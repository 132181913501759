.summary > span {
    padding: 0 10px;
}

.curve {
    height: 240px;
}

.holding {
    height: 200px;
}

.risk-detail {
  list-style: none;
  padding: 0;
}

.risk-item {
  padding: 0 40px;
}

.risk-item > .content {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  justify-content: space-between;
}

.risk-detail p {
  margin: 0;
}

.risk-item > .indicator {
}
